import { createReducer, createSelector, on } from '@ngrx/store';
import * as customerAppActions from '../actions/client.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppState } from '../../../../core/store/interfaces/app.interface';

export interface ClientState extends EntityState<any> {
  selectedId: number;
  ids: Array<any>;
  entities: { [id: number]: string };
  ui: {
    openModal: boolean;
    visibleDrawerTariffClient: boolean;
  };
  historialTariff: any[];
  clientTariff: any;
}

export const defaultState: ClientState = {
  ids: [],
  entities: {},
  selectedId: null,
  ui: {
    openModal: false,
    visibleDrawerTariffClient: false
  },
  historialTariff: [],
  clientTariff: {}
};

export const ClientAdapter: EntityAdapter<any> = createEntityAdapter<any>();
export const anysInitialState = ClientAdapter.getInitialState(defaultState);

const reducer = createReducer(anysInitialState,
  on(customerAppActions.AddClients, (state, { payload }) => ClientAdapter.setAll(payload, state) ),
  on(customerAppActions.removeAllClient, (state, {  }) => ClientAdapter.removeAll(state) ),
  on(customerAppActions.setUiClient, (state, { payload }) => ({ ...state, ui: { ...state.ui, ...payload } })),
  on(customerAppActions.selectedClient, (state, { id }) => ({ ...state, selectedId: id })),
  on(customerAppActions.loadHistorialTariffClientSuccess, (state, { historial }) => (
    ClientAdapter.setAll(historial, {
      ...state,
      loaded: true,
      loading: false,
      historialTariff: historial
    })
  )),
  on(customerAppActions.loadHistorialTariffClientError, (state, { payload }) => ({
    ...state,
    entities: {},
    loaded: false,
    loading: false,
    error: payload
  })),
  on(customerAppActions.loadTariffSuccess, (state, { tariff }) => (
    ClientAdapter.addOne(tariff, {
      ...state,
      entities: null,
      airlineTariff: tariff,
    })
  )),
  on(customerAppActions.loadTariffError, (state, { payload }) => ({
    ...state,
    error: payload
  })),
  on(customerAppActions.createTariffSuccess, (state, { tariff }) => (
    ClientAdapter.addOne(tariff, state)
  )),
  on(customerAppActions.createTariffError, (state, { payload }) => ({
    ...state,
    error: payload
  })),
  on(customerAppActions.updateTariffSuccess, (state, { tariff }) => (
    ClientAdapter.updateOne(tariff, state)
  )),
  on(customerAppActions.updateTariffError, (state, { payload }) => ({
    ...state,
    error: payload
  })),

);

export function clientReducer(state, action): any {
  return reducer(state, action);
}

export const selectFeature = ({ client }: AppState) => client;
export const UiClient = ({ client }: AppState) => client.ui;

export const getAllClients = createSelector(
  selectFeature,
  ({ ids, entities }: ClientState) => ids.map(id => entities[id])
);

export const currentClient = createSelector(
  selectFeature,
  ({ selectedId, entities }: ClientState) => !entities[selectedId] ? null : entities[selectedId]);

export const currentTariff = ({ client }: AppState) => client.clientTariff;
export const historialTariff = ({ client }: AppState) => client.historialTariff;


