import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import {
  APP_BASE_HREF,
  CommonModule,
  DatePipe,
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import en from '@angular/common/locales/en';
import { AuthInterceptorService } from './interceptor/intercept.services';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import localeEs from '@angular/common/locales/en';

registerLocaleData(localeEs, 'en');

// Translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SafePipe } from './core/pipes/safe.pipe';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { GlobalPipeModule } from './shared-modules/pipes/global-pipe.module';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { environment as env } from 'src/environments/environment';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const config: SocketIoConfig = { url: `${env.gateway.socketIO}`, options: {} };

@NgModule({
  declarations: [AppComponent, SafePipe, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NzLayoutModule,
    NzModalModule,
    NzMenuModule,
    NzAvatarModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzSpinModule,
    NzSelectModule,
    CommonModule,
    GlobalPipeModule,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en' },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    NzNotificationService,
    NzMessageService,
    DatePipe,
    {provide: APP_BASE_HREF, useValue: '/'},
  ],
  bootstrap: [AppComponent],
  exports: [SafePipe],
})
export class AppModule {}

