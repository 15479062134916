import { createAction, props } from '@ngrx/store';

export enum ClientctionsTypes {
    createClient = '[Client] Create client',
    successCreateClient = '[Client] Success Create client',
    successAllClient = '[Client] Success All client',
    addClients = '[Client] Add Clients',
    getClients = '[Client] Get Client',
    setUiClient = '[Client] Set UI Client',
    selectedClient = '[Client] Selected client',
    updateClient = '[Client] Update Client',
    removeAllClient = '[Client] Remove all Clients',
    searchClient = '[Client] Search Clients',

    CreateTariff = '[Client] Create Tariff',
    CreateTariffSuccess = '[Client] Create Tariff success',
    CreateTariffError = '[Client] Create Tariff error',
    UpdateTariff = '[Client] Update Tariff Itinerary',
    UpdateTariffSuccess = '[Client] Update Tariff Itinerary success',
    UpdateTariffError = '[Client] Update Tariff Itinerary error',
    LoadTariff = '[Client] Load Tariff Itinerary',
    LoadTariffSuccess = '[Client] Load Tariff Itinerary success',
    LoadTariffError = '[Client] Load Tariff Itinerary error',
    LoadHistorialTariffClient = '[Client] Load Historial Itinerary',
    LoadHistorialTariffClientSuccess = '[Client] Load Historial Itinerary success',
    LoadHistorialTariffClientError = '[Client] Load Historial Itinerary error',
}

export const successClient = createAction(ClientctionsTypes.successCreateClient);
export const successAllClient = createAction(ClientctionsTypes.successAllClient);
export const removeAllClient = createAction(ClientctionsTypes.removeAllClient);
export const getClients = createAction(ClientctionsTypes.getClients);

export const AddClients = createAction(
    ClientctionsTypes.addClients,
    props<{ payload: Array<any> }>()
);

export const searchClient = createAction(
    ClientctionsTypes.searchClient,
    props<{ keyword: string }>()
);

export const setUiClient = createAction(
    ClientctionsTypes.setUiClient,
    props<{ payload: {} }>()
);

export const createClient = createAction(
    ClientctionsTypes.createClient,
    props<{ payload: {} }>()
);

export const updateClient = createAction(
    ClientctionsTypes.updateClient,
    props<{ payload: {}, id: number }>()
);

export const selectedClient = createAction(
    ClientctionsTypes.selectedClient,
    props<{ id: number }>()
);



// ----------------- TARIFF ------------------------

export const createTariff = createAction(
    ClientctionsTypes.CreateTariff,
    props<{ tariff: any }>()
);

export const createTariffSuccess = createAction(
    ClientctionsTypes.CreateTariffSuccess,
    props<{ tariff: any }>()
);

export const createTariffError = createAction(
    ClientctionsTypes.CreateTariffError,
    props<{ payload: any }>()
);


export const updateTariff = createAction(
    ClientctionsTypes.UpdateTariff,
    props<{ tariff: any }>()
);

export const updateTariffSuccess = createAction(
    ClientctionsTypes.UpdateTariffSuccess,
    props<{ tariff: any }>()
);

export const updateTariffError = createAction(
    ClientctionsTypes.UpdateTariffError,
    props<{ payload: any }>()
);

export const loadTariff = createAction(
    ClientctionsTypes.LoadTariff,
    props<{ idClient: number, idItinerary: number, idAirline: number }>()
);

export const loadTariffSuccess = createAction(
    ClientctionsTypes.LoadTariffSuccess,
    props<{ tariff: any }>()
);

export const loadTariffError = createAction(
    ClientctionsTypes.LoadTariffError,
    props<{ payload: any }>()
);

export const loadHistorialTariffClient = createAction(
    ClientctionsTypes.LoadHistorialTariffClient,
    props<{ idClient: number, idItinerary: number, idAirline: number }>()
);

export const loadHistorialTariffClientSuccess = createAction(
    ClientctionsTypes.LoadHistorialTariffClientSuccess,
    props<{ historial: Array<any> }>()
);

export const loadHistorialTariffClientError = createAction(
    ClientctionsTypes.LoadHistorialTariffClientError,
    props<{ payload: any }>()
);
